// extracted by mini-css-extract-plugin
export var People_header = "People-module--People_header----ByQ";
export var People_header_body = "People-module--People_header_body--tC3b1";
export var People_header_subtitle = "People-module--People_header_subtitle--DcBEG";
export var People_header_title = "People-module--People_header_title--GIS8G";
export var People_images = "People-module--People_images--0hbzb";
export var People_work = "People-module--People_work--EUtoS";
export var People_work_header = "People-module--People_work_header--kbEUn";
export var People_work_header_link = "People-module--People_work_header_link--pFCnV";
export var container = "People-module--container--jgO1q";
export var gatsbyImageWrapperConstrained = "People-module--gatsby-image-wrapper-constrained--37v+7";
export var gridSpan1010 = "People-module--grid-span-10-10--sb6Sa";
export var gridSpan1011 = "People-module--grid-span-10-11--7Z4Jn";
export var gridSpan102 = "People-module--grid-span-10-2--qdtAJ";
export var gridSpan103 = "People-module--grid-span-10-3--5cDw+";
export var gridSpan104 = "People-module--grid-span-10-4--B3l7p";
export var gridSpan105 = "People-module--grid-span-10-5--HDD1X";
export var gridSpan106 = "People-module--grid-span-10-6--T6reW";
export var gridSpan107 = "People-module--grid-span-10-7--PuJh2";
export var gridSpan108 = "People-module--grid-span-10-8--cBVqU";
export var gridSpan109 = "People-module--grid-span-10-9--vx1xl";
export var gridSpan110 = "People-module--grid-span-1-10--EGUp4";
export var gridSpan111 = "People-module--grid-span-1-11--jnegB";
export var gridSpan1110 = "People-module--grid-span-11-10--jaYcX";
export var gridSpan1111 = "People-module--grid-span-11-11--nQmmn";
export var gridSpan112 = "People-module--grid-span-11-2--kfiRZ";
export var gridSpan113 = "People-module--grid-span-11-3--TsFrO";
export var gridSpan114 = "People-module--grid-span-11-4--Jqd6X";
export var gridSpan115 = "People-module--grid-span-11-5--AGzNJ";
export var gridSpan116 = "People-module--grid-span-11-6--finsg";
export var gridSpan117 = "People-module--grid-span-11-7--QgxQb";
export var gridSpan118 = "People-module--grid-span-11-8--lFGs8";
export var gridSpan119 = "People-module--grid-span-11-9--zLZKr";
export var gridSpan12 = "People-module--grid-span-1-2--cD88R";
export var gridSpan1210 = "People-module--grid-span-12-10--1N0Bo";
export var gridSpan1211 = "People-module--grid-span-12-11--3LMlD";
export var gridSpan122 = "People-module--grid-span-12-2--rndTQ";
export var gridSpan123 = "People-module--grid-span-12-3--BXfyW";
export var gridSpan124 = "People-module--grid-span-12-4--gW5aL";
export var gridSpan125 = "People-module--grid-span-12-5--Ed9op";
export var gridSpan126 = "People-module--grid-span-12-6--g4+rL";
export var gridSpan127 = "People-module--grid-span-12-7--auRfw";
export var gridSpan128 = "People-module--grid-span-12-8--N3FCJ";
export var gridSpan129 = "People-module--grid-span-12-9--yOCGF";
export var gridSpan13 = "People-module--grid-span-1-3--D0zM6";
export var gridSpan14 = "People-module--grid-span-1-4--WcYJc";
export var gridSpan15 = "People-module--grid-span-1-5--1RPWe";
export var gridSpan16 = "People-module--grid-span-1-6--Beb6d";
export var gridSpan17 = "People-module--grid-span-1-7--X4bdZ";
export var gridSpan18 = "People-module--grid-span-1-8--rURt+";
export var gridSpan19 = "People-module--grid-span-1-9--Wt0zO";
export var gridSpan210 = "People-module--grid-span-2-10--4DNYN";
export var gridSpan211 = "People-module--grid-span-2-11--5Q6Ul";
export var gridSpan22 = "People-module--grid-span-2-2--493Vg";
export var gridSpan23 = "People-module--grid-span-2-3--h0CMf";
export var gridSpan24 = "People-module--grid-span-2-4--e39zR";
export var gridSpan25 = "People-module--grid-span-2-5--c7LRy";
export var gridSpan26 = "People-module--grid-span-2-6--sX9yd";
export var gridSpan27 = "People-module--grid-span-2-7--KpnQr";
export var gridSpan28 = "People-module--grid-span-2-8--0PVa7";
export var gridSpan29 = "People-module--grid-span-2-9--XJCZ2";
export var gridSpan310 = "People-module--grid-span-3-10--qhIsC";
export var gridSpan311 = "People-module--grid-span-3-11--JSFNX";
export var gridSpan32 = "People-module--grid-span-3-2--PfKY9";
export var gridSpan33 = "People-module--grid-span-3-3--xrzyK";
export var gridSpan34 = "People-module--grid-span-3-4---xUUQ";
export var gridSpan35 = "People-module--grid-span-3-5--wcgS6";
export var gridSpan36 = "People-module--grid-span-3-6--98mYQ";
export var gridSpan37 = "People-module--grid-span-3-7--bTMVO";
export var gridSpan38 = "People-module--grid-span-3-8--fDbK2";
export var gridSpan39 = "People-module--grid-span-3-9--b9-Qr";
export var gridSpan410 = "People-module--grid-span-4-10--XeAi7";
export var gridSpan411 = "People-module--grid-span-4-11--nvpaM";
export var gridSpan42 = "People-module--grid-span-4-2---COKJ";
export var gridSpan43 = "People-module--grid-span-4-3--DOpA7";
export var gridSpan44 = "People-module--grid-span-4-4--S5NvH";
export var gridSpan45 = "People-module--grid-span-4-5--6D9Iu";
export var gridSpan46 = "People-module--grid-span-4-6--NjSwL";
export var gridSpan47 = "People-module--grid-span-4-7--UNyaS";
export var gridSpan48 = "People-module--grid-span-4-8--QD5iR";
export var gridSpan49 = "People-module--grid-span-4-9--Ia5K0";
export var gridSpan510 = "People-module--grid-span-5-10--ul8bn";
export var gridSpan511 = "People-module--grid-span-5-11--Qmrn2";
export var gridSpan52 = "People-module--grid-span-5-2--3Wg50";
export var gridSpan53 = "People-module--grid-span-5-3--frSkt";
export var gridSpan54 = "People-module--grid-span-5-4--qI12W";
export var gridSpan55 = "People-module--grid-span-5-5--RA3j9";
export var gridSpan56 = "People-module--grid-span-5-6--hb8WO";
export var gridSpan57 = "People-module--grid-span-5-7--GQd8s";
export var gridSpan58 = "People-module--grid-span-5-8--sHVnZ";
export var gridSpan59 = "People-module--grid-span-5-9--zP79A";
export var gridSpan610 = "People-module--grid-span-6-10--Y6idN";
export var gridSpan611 = "People-module--grid-span-6-11--q7XlE";
export var gridSpan62 = "People-module--grid-span-6-2--E9Q+5";
export var gridSpan63 = "People-module--grid-span-6-3--qg8lt";
export var gridSpan64 = "People-module--grid-span-6-4--FeK5N";
export var gridSpan65 = "People-module--grid-span-6-5--aPd3I";
export var gridSpan66 = "People-module--grid-span-6-6--Qif1W";
export var gridSpan67 = "People-module--grid-span-6-7--M0FpU";
export var gridSpan68 = "People-module--grid-span-6-8--etXYT";
export var gridSpan69 = "People-module--grid-span-6-9--9Ic8G";
export var gridSpan710 = "People-module--grid-span-7-10--9EPtS";
export var gridSpan711 = "People-module--grid-span-7-11--TM-bH";
export var gridSpan72 = "People-module--grid-span-7-2--2-k2-";
export var gridSpan73 = "People-module--grid-span-7-3--GcqyT";
export var gridSpan74 = "People-module--grid-span-7-4--2FARt";
export var gridSpan75 = "People-module--grid-span-7-5--WCRpt";
export var gridSpan76 = "People-module--grid-span-7-6--xlxbM";
export var gridSpan77 = "People-module--grid-span-7-7--E1w3b";
export var gridSpan78 = "People-module--grid-span-7-8--uIxZN";
export var gridSpan79 = "People-module--grid-span-7-9--AHsuC";
export var gridSpan810 = "People-module--grid-span-8-10--VC5Zf";
export var gridSpan811 = "People-module--grid-span-8-11--ub17j";
export var gridSpan82 = "People-module--grid-span-8-2--BvAjM";
export var gridSpan83 = "People-module--grid-span-8-3--3StRc";
export var gridSpan84 = "People-module--grid-span-8-4--nafte";
export var gridSpan85 = "People-module--grid-span-8-5--dSGg4";
export var gridSpan86 = "People-module--grid-span-8-6--GAVFf";
export var gridSpan87 = "People-module--grid-span-8-7--4U0Jd";
export var gridSpan88 = "People-module--grid-span-8-8--onXN1";
export var gridSpan89 = "People-module--grid-span-8-9--2ftcf";
export var gridSpan910 = "People-module--grid-span-9-10--r50tQ";
export var gridSpan911 = "People-module--grid-span-9-11--lZO9O";
export var gridSpan92 = "People-module--grid-span-9-2--Lnegy";
export var gridSpan93 = "People-module--grid-span-9-3---rvPq";
export var gridSpan94 = "People-module--grid-span-9-4--Ima8I";
export var gridSpan95 = "People-module--grid-span-9-5--LdZZE";
export var gridSpan96 = "People-module--grid-span-9-6--NDCOz";
export var gridSpan97 = "People-module--grid-span-9-7--Am9xS";
export var gridSpan98 = "People-module--grid-span-9-8--OdGh5";
export var gridSpan99 = "People-module--grid-span-9-9--2F+Ix";
export var textBreak = "People-module--text-break--NQUs+";