import React from "react";

import * as styles from "./Hero.module.scss";

import HeroVideo from "assets/media/video/about/Hero.mp4";

const Hero: React.FC = () => {
  return (
    <section className={styles.Hero}>
      <div className={styles.Hero_container}>
        <header className={styles.Hero_header}>
          <span className={styles.Hero_header_subtitle}>ABOUT US</span>
          <h1 className={styles.Hero_header_title}>
            Making economic prosperity more accessible to everyone
          </h1>
          <p>
            There aren't enough successes, and growth is still almost impossible
            in most parts of Africa. That should change.
            <br />
            <br /> Brass started with a simple idea of building truly useful
            services that can enable commerce and economic prosperity for
            African local businesses.
            <br />
            <br />
            We aspire to do this by building fine products that can help boost
            business activity, remove inefficiencies and provide better
            performance and growth.
          </p>
        </header>
        <div className={styles.Hero_media}>
          <video autoPlay={true} muted src={HeroVideo} />
        </div>
      </div>
    </section>
  );
};

export default Hero;
