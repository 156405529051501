// extracted by mini-css-extract-plugin
export var Perks = "Perks-module--Perks--DgCFK";
export var Perks_container = "Perks-module--Perks_container--ykPJV";
export var Perks_item = "Perks-module--Perks_item--o1iOs";
export var Perks_item_body = "Perks-module--Perks_item_body--PMC2r";
export var Perks_item_desc = "Perks-module--Perks_item_desc--PGz9v";
export var Perks_item_image = "Perks-module--Perks_item_image--D0SnR";
export var Perks_item_image_large = "Perks-module--Perks_item_image_large--Hl+Yp";
export var Perks_item_link = "Perks-module--Perks_item_link--vFe9S";
export var Perks_item_title = "Perks-module--Perks_item_title--3CFBa";
export var container = "Perks-module--container--dbZp-";
export var gatsbyImageWrapperConstrained = "Perks-module--gatsby-image-wrapper-constrained--hu2s0";
export var gridSpan1010 = "Perks-module--grid-span-10-10--vCaD4";
export var gridSpan1011 = "Perks-module--grid-span-10-11--yQkub";
export var gridSpan102 = "Perks-module--grid-span-10-2--KI-7Z";
export var gridSpan103 = "Perks-module--grid-span-10-3--CDeHc";
export var gridSpan104 = "Perks-module--grid-span-10-4--1YfN3";
export var gridSpan105 = "Perks-module--grid-span-10-5--n+K1n";
export var gridSpan106 = "Perks-module--grid-span-10-6--XObsI";
export var gridSpan107 = "Perks-module--grid-span-10-7--kRqqj";
export var gridSpan108 = "Perks-module--grid-span-10-8--i6T8D";
export var gridSpan109 = "Perks-module--grid-span-10-9--7Yldv";
export var gridSpan110 = "Perks-module--grid-span-1-10--RYRuK";
export var gridSpan111 = "Perks-module--grid-span-1-11--urfk+";
export var gridSpan1110 = "Perks-module--grid-span-11-10--9sjkS";
export var gridSpan1111 = "Perks-module--grid-span-11-11--w-sJu";
export var gridSpan112 = "Perks-module--grid-span-11-2--b-OnK";
export var gridSpan113 = "Perks-module--grid-span-11-3--wnP--";
export var gridSpan114 = "Perks-module--grid-span-11-4--1Zttj";
export var gridSpan115 = "Perks-module--grid-span-11-5--nX9f3";
export var gridSpan116 = "Perks-module--grid-span-11-6--hD26m";
export var gridSpan117 = "Perks-module--grid-span-11-7--+RLZV";
export var gridSpan118 = "Perks-module--grid-span-11-8--RosbA";
export var gridSpan119 = "Perks-module--grid-span-11-9--8Zer7";
export var gridSpan12 = "Perks-module--grid-span-1-2--muxBy";
export var gridSpan1210 = "Perks-module--grid-span-12-10--Ir5Ck";
export var gridSpan1211 = "Perks-module--grid-span-12-11--IfPIK";
export var gridSpan122 = "Perks-module--grid-span-12-2--Shooc";
export var gridSpan123 = "Perks-module--grid-span-12-3--QGed+";
export var gridSpan124 = "Perks-module--grid-span-12-4--nrDJw";
export var gridSpan125 = "Perks-module--grid-span-12-5--QWT+X";
export var gridSpan126 = "Perks-module--grid-span-12-6--hnsLB";
export var gridSpan127 = "Perks-module--grid-span-12-7--JjEGX";
export var gridSpan128 = "Perks-module--grid-span-12-8--cD8EY";
export var gridSpan129 = "Perks-module--grid-span-12-9--mlS8V";
export var gridSpan13 = "Perks-module--grid-span-1-3--gU2QZ";
export var gridSpan14 = "Perks-module--grid-span-1-4--xxvkl";
export var gridSpan15 = "Perks-module--grid-span-1-5--XAMW4";
export var gridSpan16 = "Perks-module--grid-span-1-6--r0xeZ";
export var gridSpan17 = "Perks-module--grid-span-1-7--yhiPu";
export var gridSpan18 = "Perks-module--grid-span-1-8--tuYl6";
export var gridSpan19 = "Perks-module--grid-span-1-9--6R92t";
export var gridSpan210 = "Perks-module--grid-span-2-10--wy5kJ";
export var gridSpan211 = "Perks-module--grid-span-2-11--RSFZF";
export var gridSpan22 = "Perks-module--grid-span-2-2--yFP0v";
export var gridSpan23 = "Perks-module--grid-span-2-3--M77BY";
export var gridSpan24 = "Perks-module--grid-span-2-4--8RyYn";
export var gridSpan25 = "Perks-module--grid-span-2-5--cCaZn";
export var gridSpan26 = "Perks-module--grid-span-2-6--ddGXS";
export var gridSpan27 = "Perks-module--grid-span-2-7--nnHh9";
export var gridSpan28 = "Perks-module--grid-span-2-8--8fSWd";
export var gridSpan29 = "Perks-module--grid-span-2-9--aCuFU";
export var gridSpan310 = "Perks-module--grid-span-3-10--thzb0";
export var gridSpan311 = "Perks-module--grid-span-3-11--9nm83";
export var gridSpan32 = "Perks-module--grid-span-3-2--SXbr6";
export var gridSpan33 = "Perks-module--grid-span-3-3--gEdZn";
export var gridSpan34 = "Perks-module--grid-span-3-4--b901y";
export var gridSpan35 = "Perks-module--grid-span-3-5--ohpSj";
export var gridSpan36 = "Perks-module--grid-span-3-6--MMHtf";
export var gridSpan37 = "Perks-module--grid-span-3-7--Zaxsd";
export var gridSpan38 = "Perks-module--grid-span-3-8--quFZJ";
export var gridSpan39 = "Perks-module--grid-span-3-9--Zsm3g";
export var gridSpan410 = "Perks-module--grid-span-4-10--T-plv";
export var gridSpan411 = "Perks-module--grid-span-4-11--dA03O";
export var gridSpan42 = "Perks-module--grid-span-4-2--7r8fh";
export var gridSpan43 = "Perks-module--grid-span-4-3--C733f";
export var gridSpan44 = "Perks-module--grid-span-4-4--nizbd";
export var gridSpan45 = "Perks-module--grid-span-4-5--d6ekO";
export var gridSpan46 = "Perks-module--grid-span-4-6--vXs47";
export var gridSpan47 = "Perks-module--grid-span-4-7--prr1E";
export var gridSpan48 = "Perks-module--grid-span-4-8--wfCuq";
export var gridSpan49 = "Perks-module--grid-span-4-9--sR09i";
export var gridSpan510 = "Perks-module--grid-span-5-10--7r7OO";
export var gridSpan511 = "Perks-module--grid-span-5-11--8zZLn";
export var gridSpan52 = "Perks-module--grid-span-5-2---hwJF";
export var gridSpan53 = "Perks-module--grid-span-5-3--00XJb";
export var gridSpan54 = "Perks-module--grid-span-5-4--Q3MM9";
export var gridSpan55 = "Perks-module--grid-span-5-5--lNleN";
export var gridSpan56 = "Perks-module--grid-span-5-6--A+qZU";
export var gridSpan57 = "Perks-module--grid-span-5-7--z8axS";
export var gridSpan58 = "Perks-module--grid-span-5-8--okqcE";
export var gridSpan59 = "Perks-module--grid-span-5-9--7jKTt";
export var gridSpan610 = "Perks-module--grid-span-6-10--IaUxp";
export var gridSpan611 = "Perks-module--grid-span-6-11--GI8Op";
export var gridSpan62 = "Perks-module--grid-span-6-2--97Ivf";
export var gridSpan63 = "Perks-module--grid-span-6-3--ZWz0Z";
export var gridSpan64 = "Perks-module--grid-span-6-4--775KY";
export var gridSpan65 = "Perks-module--grid-span-6-5--u0pIi";
export var gridSpan66 = "Perks-module--grid-span-6-6--qNoy6";
export var gridSpan67 = "Perks-module--grid-span-6-7--y3dRL";
export var gridSpan68 = "Perks-module--grid-span-6-8--wibJu";
export var gridSpan69 = "Perks-module--grid-span-6-9--tsmLU";
export var gridSpan710 = "Perks-module--grid-span-7-10--uq9cb";
export var gridSpan711 = "Perks-module--grid-span-7-11---X+Ys";
export var gridSpan72 = "Perks-module--grid-span-7-2--S0oDY";
export var gridSpan73 = "Perks-module--grid-span-7-3--DuYjW";
export var gridSpan74 = "Perks-module--grid-span-7-4--ooPpq";
export var gridSpan75 = "Perks-module--grid-span-7-5--3MGK4";
export var gridSpan76 = "Perks-module--grid-span-7-6--9JlFv";
export var gridSpan77 = "Perks-module--grid-span-7-7--J0KQX";
export var gridSpan78 = "Perks-module--grid-span-7-8--hKfGi";
export var gridSpan79 = "Perks-module--grid-span-7-9--5nlfP";
export var gridSpan810 = "Perks-module--grid-span-8-10--1VqaU";
export var gridSpan811 = "Perks-module--grid-span-8-11--5VmX7";
export var gridSpan82 = "Perks-module--grid-span-8-2--XB85B";
export var gridSpan83 = "Perks-module--grid-span-8-3--kbVzQ";
export var gridSpan84 = "Perks-module--grid-span-8-4--J8mzo";
export var gridSpan85 = "Perks-module--grid-span-8-5--fiTS3";
export var gridSpan86 = "Perks-module--grid-span-8-6---sS39";
export var gridSpan87 = "Perks-module--grid-span-8-7--nGBBM";
export var gridSpan88 = "Perks-module--grid-span-8-8--q+5-W";
export var gridSpan89 = "Perks-module--grid-span-8-9--Yyyep";
export var gridSpan910 = "Perks-module--grid-span-9-10--it6Tj";
export var gridSpan911 = "Perks-module--grid-span-9-11--vaGvT";
export var gridSpan92 = "Perks-module--grid-span-9-2---U9ob";
export var gridSpan93 = "Perks-module--grid-span-9-3--Anvz2";
export var gridSpan94 = "Perks-module--grid-span-9-4--PzNQC";
export var gridSpan95 = "Perks-module--grid-span-9-5--4yu8e";
export var gridSpan96 = "Perks-module--grid-span-9-6--BU1V9";
export var gridSpan97 = "Perks-module--grid-span-9-7--4bRla";
export var gridSpan98 = "Perks-module--grid-span-9-8--muo9F";
export var gridSpan99 = "Perks-module--grid-span-9-9--SvVQT";
export var textBreak = "Perks-module--text-break--V-EzT";