import React from "react";
import cx from "classnames";

import * as styles from "./Perks.module.scss";

interface PerksType {
  title: string;
  image?: string;
  description: string;
}

interface PerksProps {
  items: PerksType[];
  size?: "large" | "small";
}

const Perks: React.FC<PerksProps> = ({ items, size }) => {
  return (
    <div className={styles.Perks}>
      <div className={styles.Perks_container}>
        {items.map((quality, index) => (
          <div key={index} className={styles.Perks_item}>
            <div
              className={cx(styles.Perks_item_image, {
                [styles.Perks_item_image_large]: size === "large",
              })}
            >
              <img src={quality.image} alt="" />
            </div>
            <div className={styles.Perks_item_body}>
              <h4 className={styles.Perks_item_title}>{quality.title}</h4>
              <p className={styles.Perks_item_desc}>{quality.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

Perks.defaultProps = {
  size: "small",
};

export default Perks;
