import React from "react";
import { graphql, PageProps } from "gatsby";

import { Hero, Perks, People, Contact } from "components/about";
import { Header, Support, HighlightCard } from "components/design-system";
import SEO from "components/SEO";

import { ABOUT_DATA } from "data/pages";
import { LayoutContext } from "../../context";
import { DEFAULT_VALUES } from "../../context/Layout";
import pagesMeta from "data/pagesMeta";

const About: React.FC<PageProps> = ({ data }) => {
  const { updateContext } = React.useContext(LayoutContext);
  const {
    allJavascriptFrontmatter: {
      edges: [
        {
          node: { frontmatter: pageData },
        },
      ],
    },
  } = data as any;

  React.useEffect(() => {
    updateContext({ isFooterButtonSticky: false });

    return () => {
      updateContext(DEFAULT_VALUES);
    };
  }, []);

  return (
    <>
      <SEO {...pagesMeta.about} />
      <Hero />
      <Perks size="large" items={ABOUT_DATA.PERKS} />
      <Header
        title="What we provide"
        body="Starting and running a business is already a test of character, the business financial operation should never have to be. When we started building, we set out to provide you with a few things:"
      >
        <Support
          theme="dark"
          hasPadding={false}
          items={pageData.supportItems}
        />
      </Header>

      <People />

      <Contact />
      <HighlightCard size="large" items={ABOUT_DATA.CONTACT} />
    </>
  );
};

export const query = graphql`
  {
    allJavascriptFrontmatter(
      filter: { frontmatter: { name: { eq: "about" } } }
    ) {
      edges {
        node {
          frontmatter {
            supportItems {
              description
              title
              subtitle
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default About;
