import * as styles from "./People.module.scss";

import { Link } from "gatsby";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { careersPageUrl } from "data/pageUrl";

const People: React.FC = () => {
  return (
    <section>
      <div className={styles.People_images}>
        <StaticImage
          className="g"
          src="../../../assets/media/images/about-us-group.png"
          alt="Group"
          as="span"
        />
        <StaticImage
          className="grid-span-1-7"
          src="../../../assets/media/images/about-us-one.png"
          alt="One"
          as="span"
        />
        <StaticImage
          className="grid-span-8-5"
          src="../../../assets/media/images/about-us-two.png"
          alt="Two"
          as="span"
        />
      </div>

      <header className={styles.People_header}>
        <div>
          <span className={styles.People_header_subtitle}>OUR PEOPLE</span>
          <h2 className={styles.People_header_title}>
            The awesome people behind the scenes
          </h2>
        </div>
        <div>
          <p className={styles.People_header_body}>
            We are a team of engineers, entrepreneurs, designers and artists
            working together with a singular mission of building a new and
            better future of successful and permission-less entrepreneurship for
            everyone. <br />
            <br />
            If you run a business or ever decide to do so, we are here to
            support you.
          </p>
        </div>
      </header>

      <div className={styles.People_work}>
        <header className={styles.People_work_header}>
          <span className={styles.People_header_subtitle}>
            COME WORK WITH US
          </span>
          <h2 className={styles.People_header_title}>
            What we do is very important. Come and do meaningful work, and help
            drive our mission of making enterprising easier and more accessible
            to everyone.
          </h2>
          <Link to={careersPageUrl} className={styles.People_work_header_link}>
            Explore available opportunities
          </Link>
        </header>
      </div>
    </section>
  );
};

export default People;
